import { Link } from "react-router-dom";
function Client() {
  return (
    <>
      <section className="shipping Client">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Heading text-center">
                <h1>OUR FOUNDERS</h1>
                <img src="/img/chevron-down.svg" />
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="client-list">
                <div className="content">
                  <div className="img">
                    <img src="/img/Arif.png" />
                  </div>
                  <div>
                    <h1>Ari S.</h1>
                    <h4>Chief Technical Officer</h4>
                    <p>
                      Ari Syed, Chief Technical Officer at Boven, brings
                      extensive expertise and a strategic vision to the freight
                      hauling industry. With a strong background in engineering
                      technology and industrial design, Ari is adept at
                      optimizing operations to meet business goals. His
                      leadership in applying lean practices and driving
                      technological advancements ensures Boven achieves top
                      efficiency and performance.
                    </p>
                    <p>
                      Ari's skill in managing large-scale projects and
                      implementing innovative solutions greatly enhances
                      operational efficiency. His analytical capabilities help
                      prioritize objectives and make data-driven decisions that
                      propel the company forward. Dedicated to continuous
                      improvement, Ari consistently refines processes to reduce
                      waste and increase productivity, maintaining Boven's
                      competitive advantage in the market.
                    </p>
                    <p>
                      Fluent in English, Spanish, and Urdu, Ari effectively
                      communicates with diverse stakeholders, strengthening
                      collaboration and relationships. Ari Syed's strategic and
                      technical leadership make him a pivotal force in Boven’s
                      mission to deliver exceptional freight hauling services.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="client-list">
                <div className="content">
                  <div className="img">
                    <img src="/img/Bil.jpg" style={{ objectPosition: "top" }} />
                  </div>
                  <div>
                    <h1>Bil H.</h1>
                    <h4>Chief Operating Officer</h4>
                    <p>
                      Bil Hameed, Chief Operating Officer at Boven, brings a
                      wealth of experience in consulting and leading digital
                      transformations across technology, retail, and
                      transportation sectors. Renowned for his expertise in the
                      Scaled Agile Framework (SAFe) and project management, Bil
                      excels at optimizing operational processes and ensuring
                      seamless project execution. His strategic ability to
                      streamline operations significantly enhances Boven's
                      efficiency.
                    </p>
                    <p>
                      Bil's versatile skill set and adaptive approach enable him
                      to excel across various domains, consistently delivering
                      exceptional results. His strong communication skills and
                      strategic vision drive the company's growth. Passionate
                      about coaching and leading teams, Bil ensures that every
                      client's needs are met with precision and excellence
                      through his guidance on challenging projects.
                    </p>
                    <p>
                      His commitment to continuous improvement and operational
                      excellence boosts productivity, keeping Boven at the
                      forefront of the freight hauling market.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Client;
