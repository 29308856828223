export default function ListHero() {
  return (
    <>
      <section className="Contact ListHero" style={{ zIndex: "-1" }}>
        <div className="backimg">
          <img src="/img/index9.jpeg" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content">
                {/* <h4>
                  Our tailored shipping solutions accommodate any cargo
                  requirements, transporting your goods securely from origin to
                  final destination.
                </h4> */}
                {/* <Link to="">Contact us</Link> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
