import { Link } from "react-router-dom";
function Hero() {
  return (
    <>
      <section className="home-hero">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          class=""
        >
          <source src="/img/movingtruck.mp4" type="video/mp4" />
        </video>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero-content">
                <h1>
                  Delivering Excellence Every Mile
                  {/* WE MAKE SHIPPING BIG STUFF EASY */}
                </h1>
                {/* <p>
                  Join the millions getting bargain deals on shipping cars,
                  furniture, freight, and more…
                </p> */}
                <Link className="mt-5" to="/get-quote">
                  Request a Quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Hero;
