import { Link } from "react-router-dom";
function Work() {
  return (
    <>
      <section className="Counter">
        <div className="backimg">
          <img src="/img/plane.jpeg" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <div>
                  <p>Fleet Size</p>
                  <h4> 20+ years Combined Experience</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="content">
                <div>
                  <p>Service Areas</p>

                  <h4>48 Contiguous States</h4>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="content">
                <div>
                  <p>Safety Certifications</p>

                  <h4>7 Safety Certified, Road Trusted</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Work;
