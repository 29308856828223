import ListHero from "./section/ListHero";
import { Link } from "react-router-dom";
import BlogData from "./data/BlogData";
export default function BlogList() {
  return (
    <>
      <ListHero />
      <section className="Blog">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="Heading text-center">
                <h1>OUR BLOG</h1>
                <img src="/img/chevron-down.svg" />
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="blog-list">
                <div className="content">
                  <img src="/img/Top-Freight-Hauling-Services-Boven.jpg" />
                  <Link to="/blog-detail/boven-inc-top-freight-hauling-services">
                    Top Freight Hauling Services by Boven Inc: Reliable &
                    Efficient Solutions
                  </Link>
                  <p>
                    Looking for reliable and efficient freight hauling services?
                    Boven Inc offers unparalleled solutions that set us apart in
                    the trucking industry. Learn more here!
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="blog-list">
                <div className="content">
                  <img src="/img/Choose-Best-Freight-Hauling-Business.jpg" />
                  <Link to="/blog-detail/choose-best-freight-hauling-service">
                    How to Choose the Best Freight Hauling Service for Your
                    Business
                  </Link>
                  <p>
                    Selecting the right freight hauling service can be daunting.
                    Our comprehensive guide simplifies the process, helping you
                    make an informed decision that suits your business needs.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="blog-list">
                <div className="content">
                  <img src="/img/FTL-LTL-Freight-Hauling-Option.jpg" />
                  <Link to="/blog-detail/ftl-vs-ltl-freight-hauling">
                    FTL vs. LTL: Which Freight Hauling Option Is Right for You?
                  </Link>
                  <p>
                    Compare Full Truckload (FTL) and Less Than Truckload (LTL)
                    services to determine which option best suits your business
                    needs. Learn the key differences and benefits of each.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <div className="blog-list">
                <div className="content">
                  <img src="/img/Debunking-Common-Myths.jpg" />
                  <Link to="/blog-detail/debunking-myths-freight-hauling">
                    Debunking Common Myths About Freight Hauling
                  </Link>
                  <p>
                    Clear up common misconceptions about the freight hauling
                    industry. This post provides factual information to set the
                    record straight.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
