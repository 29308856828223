import { Link } from "react-router-dom";
import PrivacyHero from "./section/PrivacyHero";
export default function Privacy() {
  return (
    <>
      <PrivacyHero />
      <section class="Blog Blog-Detail">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="blog-list">
                <div class="content">
                  <p>
                    Thank you for visiting Boven Inc.’s website. Protecting your
                    privacy is important to us. This Privacy Policy outlines how
                    we collect, use, and safeguard your personal information
                    when you visit our website or interact with us online.
                  </p>

                  <h4>Information We Collect:</h4>
                  <p>
                    Personal Information: When you use our website or submit a
                    request for a quote, we may collect personal information
                    such as your name, company name, email address, phone
                    number, and other contact details.
                  </p>
                  <p>
                    Usage Information: We may also collect information about
                    your interactions with our website, such as your IP address,
                    browser type, pages visited, and other browsing information.
                  </p>

                  <h4>How We Use Your Information:</h4>
                  <p>
                    To Provide Services: We use your personal information to
                    respond to your inquiries, provide quotes, and fulfill your
                    requests for our transportation and logistics services.
                  </p>
                  <p>
                    Improving Our Services: We may use your information to
                    improve our website, services, and customer support.
                  </p>

                  <p>
                    Marketing Communications: With your consent, we may send you
                    promotional emails about new services or special offers. You
                    can opt out of these communications at any time.
                  </p>

                  <h4>Data Security:</h4>
                  <p>
                    We implement reasonable security measures to protect your
                    personal information from unauthorized access, alteration,
                    disclosure, or destruction.
                  </p>

                  <h4>Sharing Your Information:</h4>
                  <p>
                    We may share your personal information with our trusted
                    third-party service providers who assist us in providing
                    services to you (e.g., carriers, freight forwarders).
                  </p>

                  <h4>Your Rights:</h4>
                  <p>
                    You have the right to access, correct, or delete your
                    personal information. You can contact us at
                    contact@boveninc.com to exercise these rights.
                  </p>
                  <h4>Consent:</h4>
                  <p>
                    By submitting your personal information through our website,
                    you consent to the collection, use, and disclosure of your
                    information as described in this Privacy Policy.
                  </p>
                  <h4>Changes to This Privacy Policy:</h4>
                  <p>
                    We reserve the right to update or change this Privacy Policy
                    at any time. Any changes will be effective immediately upon
                    posting the revised Privacy Policy on our website.
                  </p>
                  <h4>Contact Us:</h4>
                  <p>
                    If you have any questions about this Privacy Policy or our
                    data practices, please contact us at{" "}
                    <a href="mailto:contact@boveninc.com">
                      contact@boveninc.com.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
