import React, { useState } from "react";
import { Link } from "react-router-dom";
import QuoteHero from "./section/QuoteHero";
import emailjs from "emailjs-com";

export default function Quote() {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [statusMessage, setStatusMessage] = useState("");

  const validate = () => {
    let tempErrors = { ...errors };
    tempErrors.name = formData.name ? "" : "Name is required.";
    tempErrors.phone = formData.phone ? "" : "Phone is required.";
    tempErrors.email = formData.email ? "" : "Email is required.";
    tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email)
      ? ""
      : "Email is not valid.";
    tempErrors.message = formData.message ? "" : "Message is required.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      emailjs
        .send(
          "service_4zstwe9", // Replace with your EmailJS service ID
          "template_f1xb1ht", // Replace with your EmailJS template ID
          formData,
          "V1aB1269bMwqFKFwd" // Replace with your EmailJS user ID
        )
        .then(
          (result) => {
            console.log(result.text);
            setStatusMessage("Email successfully sent!");

            // alert("Email successfully sent!");
          },
          (error) => {
            console.log(error.text);
            setStatusMessage("Email successfully sent!");

            // alert("Failed to send email.");
          }
        );

      setFormData({
        name: "",
        phone: "",
        email: "",
        message: "",
      });
    }
  };

  return (
    <>
      <QuoteHero />

      <div className="container getquote">
        <div className="row">
          <div className="col-lg-6 offset-lg-3">
            <div className="content">
              <h1>Request a Quote</h1>
              <p>
                Reach out to us for inquiries, partnerships, or to learn more
                about our services. We are always happy to discuss how we can
                help you achieve your goals.
              </p>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Company/Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  // required
                  placeholder="Enter Your Company/Name"
                />
              </div>

              {errors.name && (
                <div class="alert mb-3 w-100 alert-danger">{errors.name}</div>
              )}

              <div className="form-group">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Enter Your Phone"
                  // required
                />
              </div>
              {errors.phone && (
                <div class="alert mb-3 w-100 alert-danger">{errors.phone}</div>
              )}
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  // required
                  placeholder="Enter Your Email"
                />
              </div>
              {errors.email && (
                <div class="alert mb-3 w-100 alert-danger">{errors.email}</div>
              )}
              <div className="form-group">
                <label>Message</label>
                <textarea
                  rows="6"
                  name="message"
                  className="form-control"
                  value={formData.message}
                  onChange={handleChange}
                  // required
                  placeholder="Quantity and type of cargo (if known)
Frequency of shipments (one-time, regular, etc.)
Any specific questions or comments"
                />
              </div>
              {errors.message && (
                <div class="alert mb-3 w-100 alert-danger">
                  {errors.message}
                </div>
              )}
              <button type="submit">SUBMIT REQUEST</button>
              <Link to="/privacy-policy">Privacy Policy</Link>
              {statusMessage && (
                <div
                  className={`alert mt-3 w-100 ${
                    statusMessage.includes("successfully")
                      ? "alert-success"
                      : "alert-danger"
                  }`}
                >
                  {statusMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
