import Hero from "./section/hero";
import Shipping from "./section/Shipping";
import Work from "./section/Work";
import Counter from "./section/Counter";
import Contact from "./section/Contact";
import Client from "./section/Client";
import Blog from "./section/Blog";
import Routes from "./section/Routes";
function Home() {
  return (
    <>
      <Hero />
      {/* <Shipping /> */}
      <Contact />
      <Work />

      <Client />
      <Counter />

      {/* <Blog /> */}
      <Routes />
    </>
  );
}
export default Home;
