import { Link } from "react-router-dom";
function Footer() {
  return (
    <>
      <footer class="custom-footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-9 col-lsm-12">
              <div class="quicklinks">
                {/* <h4>Quick Links</h4> */}
                <ul class="list-inline">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-us">About</Link>
                  </li>
                  <li>
                    <Link to="/">Apply Now</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                  {/* <li>
                    <Link to="/">Blog</Link>
                  </li> */}
                </ul>
              </div>
              <div class="left-content">
                <h4>Contact Us</h4>
              </div>
            </div>
            <div class="col-lg-3 col-lsm-12">
              <div class="right-content">
                {/* <div>
                  <h4>Head Office</h4>
                  <p>
                    580 Hornby Street, Suite 750 Vancouver, British Columbia,V6C
                    3B6, Canada
                  </p>
                </div> */}
                <div>
                  <h4>Email</h4>
                  <p>contact@boveninc.com</p>
                </div>
                <div>
                  <h4>Phone</h4>
                  <p>(713) 581-0125</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div class="bottom-footer">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="copyright">
                <p>
                  © Copyright 2024 Boven Inc. Technologies All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Footer;
