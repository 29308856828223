export default function PrivacyHero() {
  return (
    <>
      <section className="Contact ListHero" style={{ zIndex: "-1" }}>
        <div className="backimg">
          <img
            src="/img/truck-bus-banner.jpg"
            style={{ objectPosition: "left" }}
          />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="content" style={{ marginTop: "130px" }}>
                <h4>Privacy Policy and Consent</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
