import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 250) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let navbarClasses = ["navbar menu"];
  if (scrolled) {
    navbarClasses.push("scrolled");
  }

  const handleLinkClick = () => {
    setExpanded(false);
  };

  return (
    <>
      <header className="site-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="topbar">
                <div>
                  <p>
                    Delivering nonstop, <strong>day or night.</strong>
                  </p>
                </div>
                {/* <div>
                  <Link to="">
                    CALL US <strong>(617) 242-8181</strong>
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand>
              <Link to="">
                <img src="/img/logo.png" alt="Logo" />
              </Link>
            </Navbar.Brand>
          </Container>
        </Navbar>
        <Navbar
          className={navbarClasses.join(" ")}
          expand="lg"
          expanded={expanded}
        >
          <Container>
            <Navbar.Toggle
              aria-controls="basic-navbar-nav"
              onClick={() => setExpanded(expanded ? false : "expanded")}
            />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Link
                  className="nav-link active"
                  to="/"
                  onClick={handleLinkClick}
                >
                  Home
                </Link>
                <Link
                  className="nav-link"
                  to="/about-us"
                  onClick={handleLinkClick}
                >
                  About Us
                </Link>
                <Link className="nav-link" to="/" onClick={handleLinkClick}>
                  Apply Now
                </Link>
                <Link
                  className="nav-link"
                  to="/contact-us"
                  onClick={handleLinkClick}
                >
                  Contact
                </Link>
                <Link className="nav-link" to="/blog" onClick={handleLinkClick}>
                  Blog
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
}

export default Header;
