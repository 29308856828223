import { Link } from "react-router-dom";
function Routes() {
  return (
    <>
      <section className="Routes">
        <video
          playsinline="playsinline"
          autoplay="autoplay"
          muted="muted"
          loop="loop"
          class="w-100"
        >
          <source src="/img/bovenroutes.mp4" type="video/mp4" />
        </video>
        {/* <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="video-background">
                
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  );
}
export default Routes;
