import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Header from "./component/layout/Header";
import Footer from "./component/layout/Footer";
import Home from "./component/views/home";
import BlogList from "./component/views/blog/BlogList";
// import BlogDetail from "./component/views/blog/BlogDetail";
import BlogDetailOne from "./component/views/blog/BlogDetailOne";
import BlogDetailTwo from "./component/views/blog/BlogDetailTwo";
import BlogDetailThree from "./component/views/blog/BlogDetailThree";
import BlogDetailFour from "./component/views/blog/BlogDetailFour";
import About from "./component/views/about/About";
import Quote from "./component/views/quote/Quote";
import Contact from "./component/views/contact/Contact";
import Privacy from "./component/views/privacy/Privacy";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "aos/dist/aos.css"; // Import AOS CSS
import AOS from "aos"; // Import AOS

// Initialize AOS
AOS.init();
function App() {
  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<BlogList />} />
          {/* <Route path="/blog-detail/:id" element={<BlogDetail />} /> */}
          <Route
            path="/blog-detail/boven-inc-top-freight-hauling-services"
            element={<BlogDetailOne />}
          />
          <Route
            path="/blog-detail/choose-best-freight-hauling-service"
            element={<BlogDetailTwo />}
          />
          <Route
            path="/blog-detail/ftl-vs-ltl-freight-hauling"
            element={<BlogDetailThree />}
          />
          <Route
            path="/blog-detail/debunking-myths-freight-hauling"
            element={<BlogDetailFour />}
          />
          <Route path="/about-us" element={<About />} />
          <Route path="/get-quote" element={<Quote />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="*" element={<h2>404 Not Found</h2>} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
