import AboutHero from "./section/AboutHero";
export default function About() {
  return (
    <>
      <AboutHero />
      <section class="Blog Blog-Detail">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 offset-lg-2">
              <div class="blog-list">
                <div class="content">
                  <h4>Welcome to Boven Inc.</h4>
                  <p>
                    At Boven Inc., we are dedicated to revolutionizing the
                    freight hauling industry with our innovative solutions,
                    exceptional service, and unwavering commitment to
                    excellence. Our journey began with a vision to create a
                    company that not only meets the logistical needs of
                    businesses but exceeds them, setting new standards in
                    efficiency, reliability, and customer satisfaction.
                  </p>

                  <h4>Who We Are</h4>
                  <p>
                    Founded with a passion for logistics and a drive for
                    excellence, Boven Inc. has grown into a leading name in the
                    freight hauling industry. Our team is composed of seasoned
                    professionals with extensive experience in transportation,
                    engineering, and technology, all working together to deliver
                    seamless and efficient freight solutions.
                  </p>

                  <h4>Our Mission</h4>
                  <p>
                    Our mission is to provide superior freight hauling services
                    that ensure the safe, timely, and cost-effective delivery of
                    goods. We strive to build lasting relationships with our
                    clients through transparency, trust, and exceptional
                    performance. By leveraging the latest technologies and lean
                    practices, we continuously improve our operations to meet
                    and exceed the evolving needs of our customers.
                  </p>

                  <h4>What We Do</h4>
                  <p>
                    Boven Inc. offers a comprehensive range of freight hauling
                    services tailored to meet the diverse needs of our clients.
                    From local deliveries to long-haul transportation, we ensure
                    that your goods are handled with the utmost care and
                    delivered on time. Our state-of-the-art fleet and advanced
                    tracking systems provide real-time updates, giving you peace
                    of mind and complete visibility of your shipments.
                  </p>
                  <h4>Our Values</h4>

                  <ul>
                    <li>
                      <h6>
                        Integrity:
                        <span>
                          We uphold the highest standards of integrity in all
                          our actions.
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Excellence:
                        <span>
                          We are committed to delivering excellence in every
                          aspect of our work.
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Innovation:
                        <span>
                          We embrace innovation to drive efficiency and improve
                          our services.
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Sustainability:
                        <span>
                          We are dedicated to sustainable practices that
                          minimize our environmental impact.
                        </span>
                      </h6>
                    </li>
                    <li>
                      <h6>
                        Customer Focus:
                        <span>
                          We prioritize our customers’ needs and strive to
                          exceed their expectations.
                        </span>
                      </h6>
                    </li>
                  </ul>
                  <h4>Our Team</h4>
                  <p>
                    At Boven Inc., our team is our greatest asset. Led by
                    industry experts like Bil Hameed, our Chief Operating
                    Officer, and Ari Syed, our Chief Technical Officer, we are
                    equipped with the knowledge and experience to handle the
                    most complex logistics challenges. Our team’s diverse
                    expertise and commitment to continuous improvement drive our
                    success and set us apart in the industry.
                  </p>

                  <h4>Join Us on Our Journey</h4>
                  <p>
                    We invite you to join us on our journey as we continue to
                    innovate and set new benchmarks in the freight hauling
                    industry. Whether you are a potential client, partner, or
                    team member, we look forward to building a successful and
                    enduring relationship with you.
                  </p>
                  <p>
                    Thank you for choosing Boven Inc. – Your trusted partner in
                    freight hauling.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
